<template>
    <div>
        <p class="pointer" @click="modalEdit = !modalEdit">Edit</p>
        <b-modal v-model="modalEdit" @ok="handleOk">
            <p>You pressed {{comp.name}} {{rowid}}_{{colid}}</p>
            <b-form-group label="Select component">
                <b-form-radio-group v-model="selected"
                                    :options="options"
                                    name="radios-stacked"
                                    stacked>
                </b-form-radio-group>
            </b-form-group>
        </b-modal>
    </div>
    
</template>

<script>
    export default {
        props: ['edit', 'dbid', 'itemid', 'rowh', 'rowid', 'colid', 'colp', 'comp'],
        data() {
            return {
                selected: ''
            }
        },
        computed: {
            options() {
                let c = [];
                let availableComponents = this.$store.getters.dbComponents.filter((x) => {
                    console.log("x.colprops.h: "+x.colprops.h+" this.rowh: "+this.rowh+" this.colp.h: "+this.colp.h)
                    return x.colprops.xl === this.comp.colprops.xl && x.colprops.h <= this.rowh - this.colp.h;
                });
                for (let component of availableComponents) {
                    c.push({ text: component.name, value: component.name })
                }
                return c
            },
            modalEdit: {
                
                // getter
                get: function () {
                    return this.edit
                },
                // setter
                set: function () {
                    
                 }
            }
        },

        methods: {
            handleOk() {
                // add additional component under the selected component
                if (this.selected != '') {
                    let compIndex = this.$store.getters.dbComponents.findIndex(x => x.name === this.selected);
                    let payload = {
                        dbid: this.dbid,
                        rowid: this.rowid,
                        colid: this.colid,
                        itemid: this.itemid,
                        newComp: this.$store.getters.dbComponents[compIndex]
                    }
                    this.selected = '';
                    this.$store.commit('addComp', payload);
                }
            }
        }
    };
</script>

<style scoped>
    .pointer {
        cursor: pointer;
    }
</style>